import moment from "moment";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { CONVEX_API_URL } from "../../../../config/config";

import YellowUnderlineImg from "../../../images/common/yellow-underline.webp";
import "./styles.scss";

const PastSessions = (props) => {

  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    fetchPastEvents();
  }, []);

  const fetchPastEvents = async() => {
    try {
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/latest-events?type=past`;
      
      const res = await fetch(url);
      const data = await res.json();

      if(res.ok && res.status===200)
        setPastEvents(data);     

    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: pastEvents?.length < 3 ? pastEvents.length : 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ]};

  return(
    <section className="ks-live-past-session-section">
      <div className="header-main">
        <h1 className="past-event-title ">
            Past Events
        </h1>
        <img
          src={YellowUnderlineImg}
          alt="yellow underline decoration"
          loading="lazy"
          className="yellow-underline-img"
        />
        {/* <div className="sub-title">
            Learn . Upskill . Repeat
        </div> */}
      </div>
      <div className="available-sessions">
        <Slider {...settings}>
          {
            pastEvents?.map(event => {
              return (
                // <div>
                <React.Fragment key={event.id}>
                  <PastSessionCard event={event}/>
                </React.Fragment>
                //</div>
              );
            })
          }
        </Slider>
      </div>
    </section>
  );
};

const PastSessionCard = ({event}) => {
  return (
    <div className="ks-live-past-session-card">
      <div className="session-banner" >
        <img
          src={event?.banner}
          alt= {`${event?.title} banner image`}
          loading="lazy"
        />
      </div>
      <div className="session-details-main"> 
        <div className="session-meta">
          <div>
            <b>Webinar: </b> {event?.title ?? "--"}
          </div>
          <div>
            <b>Time:</b> {moment(event?.start_date_time).isValid() ? moment(event?.start_date_time).format("LT") : "--"}
          </div>
          <div>
            <b>Date:</b> {moment(event?.start_date_time).isValid() ? moment(event?.start_date_time).format("LL"): "--"}
          </div>
          <div>
            <b>Speaker:</b> {event?.speakers}
          </div>
          {/* <div>
            <i> {event?.reg_count ? `${event?.reg_count} people registered for the session` : <br/>}</i>
          </div> */}
        </div>
        {/* <hr style={{marginBottom: "unset"}} />
        <div className="session-about-info">
          {event?.description}
        </div>
        <button className="register-btn" onClick={() => window.open(`/kraftshala-live-professional-series/register/${event?.id}`, "_top")}>
          Register Now
        </button> */}
      </div>
    </div>
  );
};

export default PastSessions;